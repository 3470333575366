import React from "react";
import {
  setActionEmail,
  setActionPassword,
  setActionIsAccept,
  reset,
  setInt
} from "@store/auth/login";
import { decodeUtf8, parseUtf8 } from "@/helpers/encryption";
import { loginSuccess, loginFail } from "@/store/auth";
import { HookContext, TContextHook, useHook } from "@/context/hook";
import { container } from "@/di/index";
import { AuthViewModel } from "@/modelviews/auth";
import { deleteCookie, getCookie, setCookie } from "cookies-next";
import { msg } from "@msg";
import { resetInfo } from "@/store/auth/profile";
import { setFormField, setVerifyCode } from "@/store/register";
import { setModal } from "@/store/global";
import { setMsg } from "@/store/global";
import dayjs from "dayjs";

export const useLoginProcess = () => {
  const hooks = React.useContext(HookContext) as TContextHook;

  const stateLogin = hooks.useSelector((state: any) => state.login);

  const authViewModel = container.resolve<AuthViewModel>(AuthViewModel);
  authViewModel.setHooks(hooks);

  const email = stateLogin.email;
  const password = stateLogin.password;

  
  const setEmail = (email: string) => { hooks.dispatch(setActionEmail(email)); };

  const setPassword = (password: string) => { hooks.dispatch(setActionPassword(password)); };

  const isAccept = stateLogin.isAccept;

  const setIsAccept = (isAccept: boolean) => { hooks.dispatch(setActionIsAccept(isAccept)); };

  const isLoading = hooks.useSelector((state: any) => state.global.isLoading);

  let validateLogin = stateLogin.validateLogin;
  validateLogin = {
    ...validateLogin,
    isFormValid:
      validateLogin.email?.isValid &&
      validateLogin?.password?.isValid
  };

  const intAuth = ()=> {
    let authSRS = localStorage.getItem( "authSR75" )

    if(!authSRS)
    return false

    authSRS = decodeUtf8(authSRS)    
    hooks.dispatch( setInt(authSRS) )
  }

  hooks.useEffect( ()=> {

    if (getCookie("rememberEmail")) {
      hooks.dispatch(setActionEmail( getCookie("rememberEmail") as string) );
      hooks.dispatch(setActionIsAccept( true ));
    }

    if( typeof window == "object" )
      intAuth()

    return () => {
      hooks.dispatch( reset() )
    }

  }, [])

  const loginUsers = ( res : any ) => {

    if( typeof window == "object" )
      localStorage.setItem( "dateStore", "" )
    
    // *** check payment when login
    if( res.data?.error == "conflict_device" )
    {
      hooks.dispatch( setModal({ modalId: "conflictDevice", isShow: true, data: null }) )
      deleteCookie( "access_token" );
      return false;
    }

    const filedRegit = ['email', 'name_sei', 'name_mei', 'password_length', 'user_type', 'token']
    // ** reriter again
    if( res?.data && Object.keys( res?.data ).filter(  (val:any) => ( filedRegit.includes(val as string) ) )?.length > 5 )
    {
        hooks.dispatch( setFormField({ value: res.data.name_sei, name: "name_sei", }) );
        hooks.dispatch( setFormField({ value: res.data.name_mei, name: "name_mei", }) );
        hooks.dispatch( setVerifyCode({ data: { email: res.data.email, token: res.data.token, }, success: true, message: res.message, }) );
        hooks.router.push("/register/chooseLabo");
        return false
    }

    if(!res.data?.access_token)
    {
      hooks.dispatch(loginFail(msg.auth.login.error as string));
      hooks.dispatch( setMsg({ text: res?.message || msg.auth.login.error as string, status: -1 }) );
      deleteCookie( "access_token" );
      return false;
    }
    
    if( !res?.data?.payment_status )
    {
      setCookie("access_token",  res?.data?.access_token  );
      hooks.router.push( `/register/card` )
      return false;
    }
    
    //if (res.success && res.data) {

      
    hooks.dispatch(loginSuccess({}));
    setCookie("access_token", res.data?.access_token, { maxAge: 1735707600 });

    const from = hooks.router.query.from as string;
    setCookie("first_login", 1);

    hooks.router.push(from ? from : "/" );

  }

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    if (isAccept) {
      localStorage.setItem( "authSR75", parseUtf8( { ... stateLogin, password: "", validateLogin: { ...stateLogin?.validateLogin, password: {} } } ).toString() )
      setCookie("rememberEmail", email);
    } else {      
      localStorage.setItem( "authSR75", "" )
      deleteCookie("rememberEmail");
    }

    const res: any = await authViewModel.login({ email, password, user_type: "lab" });
    return loginUsers(res)
  };

  const handleLoginQR = async (data: any) => {
    const res: any = await authViewModel.loginByQR(data);
    return loginUsers(res)
  };

  const handleLoglout = async (event: any ) => {
    event.preventDefault();
    
    const res: any = await authViewModel.logOut();

    if( !res?.success )
      return false;
    // logOut
    
    deleteCookie("rememberEmail");
    typeof window === "object" && localStorage.clear();
    setCookie("access_token", null);
    setCookie("ctp5", null);

    hooks.dispatch( resetInfo() );
    hooks.router.push("/login/qr");
  }
  
  const handleForgotPassword =  async () => {
   
    const res:any = await authViewModel.forgotPassword({ email, user_type: "lab" });

    if( !res.success )
    {
        hooks.dispatch( setMsg({ text: res.message, status: -1 }) );
        throw (res.message)
    }
      
    return true
  };

  const handleWithdraw = async () => {

    // wating API
    const res:any = await authViewModel.withdrawProfile({ });
    
    if(!res.success)
    {
      hooks.dispatch( setMsg({ text: res.message, status: -1 }) );
      throw (res.message)
      return false;
    }

    deleteCookie("access_token")
    deleteCookie("ctp5")

    // hooks.dispatch( setMsg({ text: res.message, status: res.success }) );
    return true
  };

  return {
    email,
    password,
    isLoading,
    setEmail,
    setPassword,
    handleLogin,
    handleLoglout,
    handleForgotPassword,
    handleWithdraw,
    handleLoginQR,
    validateLogin,
    isAccept,
    setIsAccept,
  };
};
